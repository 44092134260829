<template>
  <articles-view :articles="articles"> </articles-view>
</template>
<script>
import { defineComponent, nextTick } from "vue";
import ArticlesView from "@/components/ArticlesView";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  components: { ArticlesView },
  created() {
    document.title = this.$t('main') + " - Gro Repository";
    this.setPage(this.currentPage);
  },
  data: function() {
    return {
      pageSize: 24,
      currentPage: 0,
      totalPages: 1,
      noModsFound: false,
      articles: [],
    }
  },
  methods: {
    setPage: async function (pageIndex) {
      this.noModsFound = false;
      this.currentPage = pageIndex;
      this.mods = [];

      let start = this.currentPage * this.pageSize;
      let response = await ApiService.get(
          "api",
          `articles?start=${start}&count=${this.pageSize}`
      ).catch((reason) => console.log(reason));

      if (response && response.data) {
        this.totalPages = Math.ceil(response.data.total / this.pageSize);
        this.articles = response.data.data;
      }

      let obj = {
        query: {page: this.currentPage + 1},
      };

      if (this.articles.length === 0) {
        this.noModsFound = true;
      }

      if (obj.query.page > 1) {
        this.$router.replace(obj);
      }
    },
  },
});
</script>

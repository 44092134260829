<template>
  <div class="row">
    <div class="col-sm-7 offset-sm-3">
      <div
        v-for="(article, index) in articles"
        :key="article.id"
        :class="{ card: true, 'mt-4': index !== 0 }"
      >
        <div class="card-body">
          <a
            :href="'/article/' + (article.transliteratedTitle ? article.transliteratedTitle : article.id)"
            @click.prevent="
              this.$router.push({ name: 'article', params: { id: (article.transliteratedTitle ? article.transliteratedTitle : article.id) } })
            "
            class="card-title text-dark text-hover-primary fw-bolder fs-2" :class="{ 'color-red': !article.active }"
            >{{ $getLocalizedValue(article.title, $i18n.locale) }}</a
          >

          <div style="border-top: 1px solid #eff2f5" class="mt-1"></div>

          <article
            class="mt-2"
            v-html="$getLocalizedValue(article.shortContent, $i18n.locale)"
          ></article>
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <p class="card-title fw-bolder fs-7 mt-4" style="color: #424242">
              <i class="fa fa-user me-1 fs-5" style="color: #009ef7"></i
              >&nbsp;{{ article.author }}
              &nbsp;&nbsp;
              <i class="fa fa-calendar-alt me-1 fs-5" style="color: #009ef7"></i
              >&nbsp;{{ decorateDate(article.createdDate) }}
            </p>
            <button
              class="btn btn-primary btn-sm"
              @click="
                this.$router.push({
                  name: 'article',
                  params: { id: (article.transliteratedTitle ? article.transliteratedTitle : article.id) },
                })
              "
            >
              {{ $t("read_more") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "ArticlesView",
  props: {
    articles: { type: Array },
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },
  },

  data: function () {
    return {};
  },

  methods: {
    downloadGrorepDesktop() {
      ApiService.get("api", "grorep-desktop").then((response) => {
        window.open(response.data, "blank");
      });
    },
    decorateDate(dt) {
      let lang = localStorage.getItem("lang")
        ? localStorage.getItem("lang")
        : "en";

      const formatter = new Intl.DateTimeFormat(lang, {
        day: "numeric",
        month: "long",
        year: "numeric",
      });
      return formatter.format(new Date(dt));
    },

    userHasGrant: function (grant) {
      return (
        this.currentUser &&
        this.currentUser.roles &&
        this.currentUser.roles.indexOf(grant) !== -1
      );
    },
  },
});
</script>
<style>
.color-red {
  color: red !important;
}
</style>
